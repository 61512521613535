<template>
  <section class="tooltip" :class="[position, { focussed: focussed }]" @click.stop @mouseover="showTooltip" @mouseleave="hideTooltip"
    @click="focusTooltip" @blur="blurTooltip" tabindex="0">
    <div class="toggle" ref="toggle">
      <slot name="toggle">
        <icon :icon="icon" size="s" />
      </slot>
    </div>
    <transition name="show-tooltip">
      <div v-if="visible" class="tooltip-content-container">
        <section class="tooltip-content">
          <slot name="text">
            <span class="text"> {{ text }} </span>
          </slot>
        </section>
      </div>
    </transition>
  </section>
</template>

<script setup>
import { computed, ref } from 'vue';
import Icon from '../icon/Icon.vue';

const props = defineProps({
  icon: {
    type: String,
    default: 'info-circle',
  },
  text: String,
  closeToggle: {
    type: Boolean,
    default: true,
  },
  position: {
    required: false,
    type: String,
    default: 'top-right',
    validator: (value) =>
      [
        'top-left',
        // 'top-center',
        'top-right',
        'bottom-left',
        // 'bottom-center',
        // 'bottom-right',
      ].includes(value),
  },
});


const hovered = ref(false);
const focussed = ref(false);

const showTooltip = () => {
  hovered.value = true;
};

const hideTooltip = () => {
  hovered.value = false;
};

const focusTooltip = () => {
  if (focussed.value) {
    focussed.value = false;
    hovered.value = false;
  }
  else {
    focussed.value = true;
  }
};

const blurTooltip = () => {
  focussed.value = false;
};

const visible = computed(() => hovered.value || focussed.value);
</script>

<style lang="scss" scoped>
@use 'assets/scss/all' as *;
@use './tooltip';

.tooltip {
  @include tooltip.manual-trigger();


  &.focussed {
    border-radius: 50%;
    box-shadow: 0 0 2px 1px $link-base-active;
  }

  &.top-right {
    @include tooltip.top-right();
  }

  &.top-left {
    @include tooltip.top-left();
  }

  // &.bottom-right {
  //   @include tooltip.bottom-right();
  // }

  &.bottom-left {
    @include tooltip.bottom-left();
  }

  .show-tooltip-enter-active,
  .show-tooltip-leave-active {
    transition-property: transform, opacity;
    transition-duration: 0.35s;
    transition-timing-function: cubic-bezier(.75, -0.75, .25, 1.45);
  }

  .show-tooltip-enter-from,
  .show-tooltip-leave-to {
    opacity: 0;
    transform: translate(-16px, 16px);
  }

}
</style>
